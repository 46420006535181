html, body, #container, .dialog-backdrop, .bigOverlay {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

body, .bigOverlay {
  background-color: #12151a;
}

.bigOverlay {
  z-index: 999;
  height: 100%;
  color: #fff;
  white-space: nowrap;
  height: calc(100% - 40px);
  justify-content: center;
  align-items: center;
  font-size: 3em;
  display: flex;
}

.dialog-backdrop {
  z-index: 1000;
  background-color: #000000d9;
}

.dialog-backdrop.gradientEdit {
  text-align: center;
}

.dialog-backdrop.gradientEdit .dialog {
  flex-direction: row;
  display: flex;
}

.dialog-backdrop.gradientEdit .leftCol {
  margin-right: 16px;
}

.dialog-backdrop.gradientEdit .leftCol > canvas {
  border-radius: 10px;
}

.dialog-backdrop.gradientEdit .rightCol {
  flex-grow: 2;
}

.dialog-backdrop.gradientEdit canvas {
  box-sizing: border-box;
  border: none;
}

.dialog-backdrop.gradientEdit canvas.preview {
  width: 250px;
  margin-top: 0;
  display: inline-block;
}

.dialog-backdrop.gradientEdit .labelSlider {
  height: 32px;
  color: #fff;
  background-color: #08090b;
  border: none;
  border-radius: 5px;
  line-height: 32px;
  position: relative;
}

.dialog-backdrop.gradientEdit .labelSlider:hover {
  background-color: #1c2129;
}

.dialog-backdrop.gradientEdit input[type="color"] {
  height: 32px;
  margin-top: 6px;
}

.dialog-backdrop.gradientEdit .gradientSlider {
  width: auto;
  display: inline-block;
}

.dialog-backdrop.gradientEdit .row {
  box-sizing: border-box;
  flex-direction: row;
  align-items: stretch;
  display: flex;
}

.dialog-backdrop.gradientEdit .row .ok {
  flex-grow: 1;
}

.dialog-backdrop.gradientEdit .row .label {
  flex-direction: row;
  flex-grow: 0;
  align-items: center;
  display: flex;
}

.dialog-backdrop.gradientEdit .row > * {
  flex-grow: 1;
  margin: 0 4px;
  font-size: 16px;
}

.dialog-backdrop.gradientEdit .row > :first-child {
  margin-left: 0;
}

.dialog-backdrop.gradientEdit .row > :last-child {
  margin-right: 0;
}

.dialog {
  color: #fff;
  min-width: 500px;
  max-width: 80%;
  max-height: 70vh;
  height: auto;
  text-align: center;
  background-color: #12151a;
  border-radius: 5px;
  padding: 12px 28px;
  position: absolute;
  top: 10%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%);
}

.dialog.closable {
  max-width: calc(100% + 32px);
  max-height: calc(70vh + 24px);
  min-width: 556px;
  padding: 0;
  overflow: hidden;
}

.dialog.closable .close {
  cursor: pointer;
  font-size: 2em;
  position: absolute;
  top: 12px;
  right: 16px;
}

.dialog.closable .innerContent {
  box-sizing: border-box;
  max-height: inherit;
  padding: 12px 40px;
  overflow: auto;
}

.dialog-whatsNew h1 {
  text-align: center;
  margin-bottom: 0;
}

.dialog-whatsNew h2 {
  text-align: left;
  margin: 16px 0 0;
  padding: 0;
  font-size: 1em;
  text-decoration: underline;
}

.dialog-whatsNew ul {
  text-align: left;
  margin: 8px 0;
  padding-left: 24px;
}

.dialog-whatsNew ul li {
  margin: 4px 0;
}

.dialog-supporters .innerContent {
  padding: 24px !important;
}

.dialog-supporters h1 {
  margin-top: 0;
}

.dialog-render .pleaseWait {
  color: #c00;
  margin-top: 4px;
  font-style: italic;
}

.dialog-render .optionBar {
  width: 100%;
  overflow: hidden;
}

.dialog-render .optionBar .option {
  width: 228px;
  background: #08090b;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 4px;
  padding: 6px;
  display: inline-block;
}

.dialog-render .optionBar .option:first-of-type {
  margin: 0 10px 0 0;
}

.dialog-render .optionBar .option:last-of-type {
  margin: 5px 0 5px 10px;
}

.dialog-render .optionBar .option:hover {
  background: #1c2129;
}

.dialog-render .optionBar .option.toggled {
  color: #fff;
  background: #1c2129;
}

.dialog-render .optionBar .option.toggled:hover {
  cursor: auto;
  background: #1c2129;
}

.dialog-render .image {
  max-width: 300px;
  min-width: 300px;
  margin: 8px 0;
}

.dialog-render .header {
  margin: 0;
  font-size: 32px;
  font-weight: normal;
}

.dialog-render .option {
  color: #fff;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  background: #08090b;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

.save {
  opacity: .8;
  color: #fff;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  background: linear-gradient(45deg, #f2ff00 0%, #9a8d13 100%) 0 0 / 200% 100%;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  text-decoration: none;
  animation: 3s infinite alternate gradient;
  display: block;
}

.save:hover {
  opacity: 1;
}

.dialog-render .option:hover, .dialog-render .save:hover {
  background-color: #1c2129;
}

.progressBar {
  height: 32px;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 8px 0;
}

.progressBar .progress {
  height: 100%;
  width: 0;
  background: #58f;
  border-radius: 3px;
}

.dialog-tutorial h1 {
  margin: 8px 0;
  font-size: 32px;
}

.dialog-tutorial .image {
  max-width: 300px;
  min-width: 300px;
  margin: 16px 0 12px;
}

#container {
  z-index: 0;
  height: calc(100% - 40px);
}

.link, #footer .link {
  cursor: pointer;
  color: #ddd;
  text-decoration: underline;
}

.link:hover, #footer .link:hover {
  color: #fff;
}

#footer {
  height: 40px;
  color: #fff;
  z-index: 1;
  background: #12151a;
  padding: 0 8px;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 16px;
  line-height: 40px;
  text-decoration: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#footer * {
  color: #fff;
  display: inline-block;
  position: relative;
  top: 1px;
}

#footer div {
  height: 40px;
  line-height: 40px;
}

#footer #footerLeft {
  margin-left: 12px;
  position: absolute;
  top: 0;
}

#footer #footerRight {
  position: absolute;
  top: 0;
  right: 0;
}

#footer #footerRight > * {
  margin-right: 12px;
}

#footer #closeFooter {
  cursor: pointer;
}

#footer .linkbox {
  max-height: 30px;
  background-color: #08090b;
  border-radius: 5px;
  padding: 0 10px;
  transition: all .25s;
  top: 5px;
}

#footer .linkbox:hover {
  background-color: #1c2129;
}

#footer .link {
  text-align: center;
  max-height: 30px;
  text-decoration: none;
  top: -5px;
  bottom: 5px;
}

#f {
  z-index: 1;
  position: absolute;
  left: 800px;
}

.borderWindow {
  height: 100%;
  width: calc(100% - 256px);
  background: #1d2129;
  border-radius: 0 20px 20px 0;
}

.renderShadow {
  height: 45px;
  width: 246px;
  filter: blur(12px);
  z-index: -1;
  background: linear-gradient(45deg, #f2ff00 0%, #9a8d13 100%) 0 0 / 200% 100%;
  margin: 0 auto 10px 0;
  animation: 3s infinite alternate gradient;
  position: relative;
}

.cropView {
  -webkit-user-select: none;
  user-select: none;
  width: 700px;
  height: 100%;
  background: none;
  position: absolute;
  top: 0;
  overflow: auto;
}

.cropView canvas {
  z-index: 1;
}

.cropView .image, .cropView canvas {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.zoomFit, .zoomIn, .zoomOut {
  margin: 0 !important;
}

.open {
  text-align: center;
}

.render {
  margin-top: 0 !important;
}

.menu {
  color: #eee;
  width: 256px;
  z-index: 3;
  height: 100%;
  background: #12151a;
  padding: 10px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.menu .item, .menu .item > button {
  pointer-events: all;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  color: #eee;
  height: 45px;
  width: 246px;
  box-sizing: content-box;
  float: left;
  background-color: #08090b;
  border: 0;
  border-radius: 7px;
  margin: 5px 5px 0;
  padding: 0;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-size: 18px;
  line-height: 45px;
  text-decoration: none;
  transition: all .25s ease-in-out;
  position: relative;
}

.menu .item.shapeToggle, .menu .item > button.shapeToggle {
  cursor: pointer;
}

.menu .item.shapeToggle.toggled:hover, .menu .item > button.shapeToggle.toggled:hover {
  cursor: auto;
}

.menu .item.half, .menu .item > button.half {
  width: 120px;
}

.menu .item.half.lefthalf, .menu .item > button.half.lefthalf {
  margin: 5px 6px 0 5px;
}

.menu .item.half.righthalf, .menu .item > button.half.righthalf {
  margin: 5px 5px 0 0;
}

.menu .item.toggled, .menu .item > button.toggled {
  color: #fff;
  background: #272d38;
}

.menu .item.toggled:hover, .menu .item > button.toggled:hover {
  background: #272d38;
}

.menu .item:hover, .menu .item > button:hover {
  box-sizing: border-box;
  background-color: #1c2129;
}

.menu .item .openInput, .menu .item > button .openInput {
  display: none;
}

.menu .item.zoomBar.zoomLabel, .menu .item > button.zoomBar.zoomLabel {
  cursor: auto;
}

.menu .item.zoomBar.zoomLabel:hover, .menu .item > button.zoomBar.zoomLabel:hover {
  background-color: #08090b;
}

.menu .item.zoomBar > button, .menu .item > button.zoomBar > button {
  width: 48px;
  float: right;
  background-color: #08090b;
}

.menu .item.zoomBar > button:hover, .menu .item > button.zoomBar > button:hover {
  color: #fff;
  background: #1c2129;
}

.menu .item.zoomBar .zoomLabel, .menu .item > button.zoomBar .zoomLabel {
  display: inline;
}

.menu .item#borderSelect.edit, .menu .item > button#borderSelect.edit {
  width: calc(100% - 32px);
}

.menu .item.borderEdit, .menu .item > button.borderEdit {
  width: 32px;
  padding: 0;
}

.menu .item.render, .menu .item > button.render {
  color: #fff;
  opacity: .8;
  background: linear-gradient(45deg, #f2ff00 0%, #9a8d13 100%) 0 0 / 200% 100%;
  animation: 3s infinite alternate gradient;
}

.menu .item.render:hover, .menu .item > button.render:hover {
  opacity: 1;
  transition: opacity .25s;
}

@media (max-width: 1024px) {
  .dialog-backdrop.gradientEdit .dialog {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    top: 0;
    left: 0;
    transform: none;
  }
}

@media (max-width: 824px) {
  .dialog-backdrop.gradientEdit .dialog {
    flex-direction: column;
  }

  .dialog-backdrop.gradientEdit .dialog .leftCol {
    margin-bottom: 16px;
    margin-right: 0;
  }
}

.dialog-backdrop.gradientEdit .leftCol > *, .dialog-backdrop.gradientEdit .rightCol > * {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  display: block;
}

.dialog-backdrop.gradientEdit .leftCol > :first-child, .dialog-backdrop.gradientEdit .rightCol > :first-child {
  margin-top: 0;
}

.dialog-backdrop.gradientEdit button, .dialog-backdrop.gradientEdit select, .dialog-backdrop.gradientEdit input[type="color"] {
  cursor: pointer;
  color: #fff;
  box-sizing: border-box;
  background-color: #08090b;
  border: none;
  border-radius: 5px;
  padding: 4px;
}

.dialog-backdrop.gradientEdit button:hover, .dialog-backdrop.gradientEdit select:hover, .dialog-backdrop.gradientEdit input[type="color"]:hover {
  background-color: #1c2129;
}

.gradientSlider {
  text-align: left;
  position: relative;
}

.gradientSlider .stop {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;
  border: 3px solid #08090b;
  border-radius: 20px;
  position: absolute;
  top: -2px;
  bottom: 1px;
}

.gradientSlider .stop.current {
  border: 3px solid #393a3b;
}

.labelSlider .labelSlider-label {
  width: 100%;
  height: 100%;
}

.labelSlider .labelSlider-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  opacity: .5;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.labelSlider .labelSlider-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
}

.labelSlider .labelSlider-slider::-webkit-slider-thumb {
  height: 32px;
  width: 32px;
  cursor: pointer;
  -webkit-appearance: none;
  background: #eee;
  border-radius: 12px;
  margin-top: 0;
}

.labelSlider .labelSlider-slider::-moz-range-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
}

.labelSlider .labelSlider-slider::-moz-range-thumb {
  height: 32px;
  width: 32px;
  cursor: pointer;
  background: #eee;
  border: 0;
  border-radius: 12px;
}

.labelSlider .labelSlider-slider::-ms-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
}

.labelSlider .labelSlider-slider::-moz-range-thumb-ms-thumb {
  width: 32px;
  cursor: pointer;
  height: 32px;
  background: #eee;
  border: 0;
  border-radius: 12px;
}

.previews {
  z-index: 1;
  background: none;
  position: absolute;
  bottom: 16px;
  right: 256px;
}

.previews .preview {
  border-radius: 5px;
  bottom: -2px;
  overflow: hidden;
}

.previews .preview .onlineIndicator {
  z-index: 2;
  position: absolute;
  top: 20px;
  left: 18px;
}

.previews .preview .bottomBar {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.previews .preview .bottomBar .size {
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  z-index: 2;
  background-color: #eee;
  border-top: 1px solid #aaa;
  border-right: 1px solid #aaa;
  margin: 0;
  padding: 0 8px;
  line-height: 32px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 32px;
  overflow: hidden;
}

.previews .preview .bottomBar .remove {
  cursor: pointer;
  width: 32px;
  height: 32px;
  z-index: 3;
  box-sizing: border-box;
  background: #eee 0;
  border: 0;
  border-top: 1px solid #aaa;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.paypal__submit-image {
  background: none;
  border: 0;
  outline: none;
}

@media (max-width: 800px) {
  .dialog-backdrop .dialog {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    transform: unset;
    box-sizing: border-box;
    text-align: center;
    border-radius: 0;
    margin: 0;
    top: 0;
    left: 0;
  }

  .dialog-render .save {
    margin-bottom: 8px;
  }
}

@media (max-width: 940px) {
  #link-whatsNew {
    display: none;
  }
}

@media (max-width: 770px) {
  #link-discord {
    display: none;
  }
}

@media (max-width: 700px) {
  #link-help {
    display: none;
  }
}

@media (max-width: 570px) {
  #footerLeft {
    display: none;
  }
}

@media (max-width: 450px) {
  #footer {
    display: none;
  }
}

@media (max-width: 375px) {
  #footer {
    display: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

/*# sourceMappingURL=index.520dc514.css.map */
